<template>
  <Details
    title="Customer Role"
    resource="customerRoles"
    base-path="customers.roles.index"
    :request-params="requestParams"
    routeIdParam="roleId"
    title-template="{name} role"
  >
    <template v-slot:default="detailsProps">
      <div class="flex h-full mb-4 justify-start border border-gray-300 bg-gray-50 p-8 mb-8">
        <div class="w-1/2">
          <div>
            <div class="label">application</div>
            <div>{{detailsProps.record.appName}}</div>
          </div>
        </div>
        <div class="w-1/2">
          <div>
            <div class="label">role description</div>
            <span class="font-bold">{{detailsProps.record.name}}</span>
            <span class="text-gray-500 pl-4">ID: {{detailsProps.record.id}}</span>
          </div>
          <div>
            <span>{{detailsProps.record.description}}</span>
          </div>
        </div>
      </div>
      <div>
        <Vuetable
          class="permissions"
          :rowClass="rowClass"
          :css="css.table"
          :api-mode="false"
          :fields="fields"
          :data="{data: detailsProps.record.permissions}"
          :data-manager="dataManager"
          :sort-order="sortOrder"
          track-by="permissionId"
        >
          <template v-slot:selected="props">
            <div>
              <input type="checkbox" :checked="props.rowData.selected" @click.prevent="() => {}">
            </div>
          </template>
        </Vuetable>
      </div>
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import Details from '@/components/auth/details/Details';
  import Vuetable from 'vue3-vuetable';

  const css = {
    table: {
      tableClass: 'table-auto w-full',
      tableBodyClass: '',
      tableHeaderClass: 'px-4 py-2',
      tableWrapper: '',
      loadingClass: 'loading',
      ascendingIcon: 'blue chevron up icon',
      descendingIcon: 'blue chevron down icon',
      ascendingClass: 'sorted-asc',
      descendingClass: 'sorted-desc',
      sortableIcon: 'grey sort icon',
      handleIcon: 'grey sidebar icon',
    },
  };

  export default {
    name: 'RoleDetails',
    mixins: [ModalNavigation],
    components: {
      Details,
      Vuetable,
    },
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
    },
    data() {
      return {
        css,
        fields: [
          {
            name: 'name',
            title: 'Permission Name',
            sortField: 'name',
          },
          {
            name: 'value',
            title: 'Permission Value',
            sortField: 'value',
          },
          {
            name: 'description',
            title: 'Permission Description',
          },
        ],
        sortOrder: [],
        requestParams: { customerId: this.$route.params.customerId, sort: null}
      };
    },
    methods: {
      dataManager(sortOrder = []) {
        const sort = sortOrder[0]
            ? `${sortOrder[0].sortField},${sortOrder[0].direction}`
            : undefined;

        this.requestParams = {
          ...this.requestParams,
          sort,
        };
        this.sortOrder = sortOrder;
      },
      rowClass(item) {
        return item.selected ? '' : 'row--disabled';
      },
    }
  };
</script>

<style scoped>
  .permissions :deep(.row--disabled > td) {
    @apply text-gray-400;
  }
</style>
